/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

/// ///////////////Strategy//////////////////////
interface URLStrategy {
  generateURL(
    bucketName: string,
    path: string,
    filename: string,
    accountName?: string,
  ): string;
}

class GSURLStrategy implements URLStrategy {
  generateURL(bucketName: string, path: string, filename: string): string {
    return `gs://${bucketName}/${path}/${filename}`;
  }
}

class HTTPSGCPURLStrategy implements URLStrategy {
  generateURL(bucketName: string, path: string, filename: string): string {
    const gcpStorageUrl =
      process.env.GCP_STORAGE_URL ?? 'https://storage.cloud.google.com';
    return `${gcpStorageUrl}/${bucketName}/${path}/${filename}`;
  }
}

class PUBLICHTTPSGCPURLStrategy implements HTTPSGCPURLStrategy {
  generateURL(bucketName: string, path: string, filename: string): string {
    const gcpStorageUrl = 'https://storage.googleapis.com';
    return `${gcpStorageUrl}/${bucketName}/${path}/${filename}`;
  }
}

class AzureURLStrategy implements URLStrategy {
  generateURL(
    bucketName: string,
    path: string,
    filename: string,
    accountName: string,
  ): string {
    return `https://${accountName}.blob.core.windows.net/${bucketName}/${path}/${filename}`;
  }
}

/// //////////////Factory///////////////////////

export type URLFormat = 'gs' | 's3' | 'httpsgcp' | 'httpsgcpPublic' | 'azure';

class URLStrategyFactory {
  static createURLStrategy(format: URLFormat): URLStrategy {
    switch (format) {
      case 'gs':
        return new GSURLStrategy();
      case 'httpsgcp':
        return new HTTPSGCPURLStrategy();
      case 'httpsgcpPublic':
        return new PUBLICHTTPSGCPURLStrategy();
      case 'azure':
        return new AzureURLStrategy();
      default:
        throw new Error(`Unsupported URL format: ${format}`);
    }
  }
}

export class URLContext {
  private strategy: URLStrategy;

  constructor(format: URLFormat) {
    this.strategy = URLStrategyFactory.createURLStrategy(format);
  }

  setFormat(format: URLFormat) {
    this.strategy = URLStrategyFactory.createURLStrategy(format);
  }

  generateURL(
    bucketName: string,
    path: string,
    filename: string,
    accountName?: string,
  ): string {
    return this.strategy.generateURL(bucketName, path, filename, accountName);
  }
}
