import React, { useEffect, useRef, useState } from 'react';
import { ReactTyped } from 'react-typed';

type Props = {
  durationInMS: number;
};

const carouselData = [
  {
    title: 'AI Studio\nEnsures Legal\nCompliance',
    prompt: 'Does the SRS align with industry standards?',
    description: 'Planning workflow is generating a response...',
  },
  {
    title: 'Generating Test\nCases 2x Faster\nThan Usual',
    prompt: 'I need to create test cases for a project..',
    description: 'Testing workflow is generating a response...',
  },
  {
    title: 'Asks for a\ntemplate for a\nspecific proposal',
    prompt: 'Can you provide a proposal template?',
    description: 'Proposal gathering workflow is generating a response...',
  },
  {
    title: 'Optimizing\nCode Efficiency\nand Speed',
    prompt: 'How can I improve the performance of my code?',
    description: 'Development workflow is generating a response...',
  },
];

const LandingPageCarousel = ({ durationInMS }: Props) => {
  const [index, setIndex] = useState(0);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState('358px');
  const [cursorBlinking, setCursorBlinking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (index === carouselData.length - 1) {
        setIndex(0);
      } else {
        setIndex((prev) => prev + 1);
      }
    }, durationInMS);

    return () => {
      clearInterval(interval);
    };
  }, [index, durationInMS]);

  useEffect(() => {
    // Create a temporary element to measure the text width
    const measureTextWidth = (text: string) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return 358;
      context.font = "18px 'PwC Helvetica Neue'"; // Use the same font and size as your text
      return context.measureText(text).width;
    };

    const width = measureTextWidth(carouselData[index]?.prompt as string);
    setContainerWidth(`${width + 17}px`); // Set the initial width of the container
  }, [index]);

  return (
    <div className="flex flex-1 flex-col gap-8">
      <span className="whitespace-pre text-wrap text-[50px] font-bold leading-tight text-textHeading 2xl:text-[70px]">
        {carouselData[index]?.title}
      </span>
      <div className="flex flex-col gap-4">
        <div className="flex w-fit items-center gap-8 rounded-2xl bg-[#FFF2D2] bg-opacity-75 px-6 py-7">
          <span
            ref={containerRef}
            style={{ width: containerWidth }}
            className="flex items-center gap-2 whitespace-pre text-lg leading-normal text-[#333]"
          >
            <ReactTyped
              onBegin={() => setCursorBlinking(false)}
              onStringTyped={() => setCursorBlinking(true)}
              showCursor={false}
              strings={[carouselData[index]?.prompt as string]}
              typeSpeed={50}
            />
            <div
              className={`h-[26px] w-[9px] bg-primaryBlue ${cursorBlinking && 'animate-pulse-quick'}`}
            />
          </span>
          <span className="Appkit4-icon icon-paper-airplane-outline ap-font-24" />
        </div>
        <span className="whitespace-pre text-base leading-normal text-textHeading">
          {carouselData[index]?.description}
        </span>
      </div>
    </div>
  );
};

export default LandingPageCarousel;
