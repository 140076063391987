import React from 'react';

import type { URLFormat } from '@/libs/DocumentUrlFactory';
import { URLContext } from '@/libs/DocumentUrlFactory';

const azureParams =
  '?sp=r&st=2024-11-03T15:57:08Z&se=2040-11-03T23:57:08Z&sv=2022-11-02&sr=b&sig=VV7XftdPXMuR4E8qJmGv5sm%2F%2B4msda5hVjH8pvrTncI%3D';
const LandingPagePromo = async () => {
  const accountName = process.env.AZURE_STORAGE_ACCOUNT_NAME as string;
  const provider =
    (process.env.NEXT_UPLOAD_URL_PROVIDER as URLFormat) === 'gs'
      ? 'httpsgcpPublic'
      : ((process.env.NEXT_UPLOAD_URL_PROVIDER as URLFormat) ??
        'httpsgcpPublic');
  const urlContext = new URLContext(provider);
  let fileUrl = urlContext.generateURL(
    'ais-static-assets',
    'videos',
    'AI_Studio_Promo.mp4',
    accountName,
  );
  if (provider === 'azure') {
    fileUrl += azureParams;
  }

  return (
    <div>
      <video
        className="rounded-2xl"
        controls
        controlsList="nodownload"
        autoPlay
        muted
        width="100%"
      >
        <source src={fileUrl} type="video/mp4" />
      </video>
    </div>
  );
};

export default LandingPagePromo;
